.content-image--container {
    width: 100%;
    height: 90vh;
    margin-top: 10vh;
}

.content-image--image {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 16px;
}

.content-image--text {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}
