.content-text--container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.content-text--text {
    text-align: center;
    font-weight: bold;
}
