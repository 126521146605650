body {
    background-color: #131313;
}

.admin-page {
    padding: 20px;
    color: white;
    font-family: Barlow, sans-serif;
}

.header {
    margin-bottom: 20px;
}

.header-logo > svg {
    width: 36px;
    height: 36px;
}

.header-title {
    display: inline-block;
    font-family: Barlow, sans-serif;
    font-size: 50px;
    font-weight: 200;
    letter-spacing: -2px;
}

.filter {
    margin: 20px 0;
}

.filter-select {
    display: inline;
    width: 30%;
    height: 42px;
    background-color: black;
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-radius: 16px;
    outline: none;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: Barlow, sans-serif;
    font-size: 18px;
    font-weight: 900;
    transition: border-color .2s ease-out;
}

.filter-select:focus {
    border-color: rgba(255, 255, 255, 0.75);
}

.filter-text {
    float: right;
    width: 65%;
    height: 42px;
    padding-left: 10px;
    box-sizing: border-box;
    background-color: black;
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-radius: 16px;
    outline: none;
    color: white;
    text-transform: uppercase;
    font-family: Barlow, sans-serif;
    font-size: 18px;
    font-weight: 900;
    transition: border-color .2s ease-out;
}

.filter-text:focus {
    border-color: rgba(255, 255, 255, 0.75);
}

.filter-text::placeholder {
    color: white;
}

.create-content-button {
    display: inline-block;
    float: right;
    color: white;
    text-align: right;
    text-decoration: none;
    font-size: 3em;
    font-weight: bold;
}

@keyframes intro-bg {
    0% {
        background-color: white;
    }
    1% {
        background-color: #131313;
    }
    100% {
        background-color: #131313;
    }
}

.admin-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    animation-delay: 1.3s;
    animation-duration: 1s;
    animation-name: intro-bg;
    animation-fill-mode: forwards;
}

@keyframes intro {
    0% {
        background-color: white;
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    39% {
        background-color: white;
    }
    40% {
        box-shadow: 0 0 0 0 #131313;
        background-color: #131313;
    }
    50% {
        box-shadow: 0 0 0 1000px #131313;
        opacity: 1;
    }
    100% {
        box-shadow: 0 0 0 1000px #131313;
        opacity: 0;
    }
}

.admin-loading--logo {
    animation-duration: 3s;
    animation-name: intro;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes intro-title {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.admin-loading--title {
    margin-left: 10px;
    opacity: 0;
    color: white;
    font-family: Barlow, sans-serif;
    font-size: 50px;
    font-weight: 200;
    letter-spacing: -2px;
    animation-duration: .5s;
    animation-name: intro-title;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes intro-title-dot {
    0% {
        opacity: 0;
    }
    1% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.admin-loading--title:after {
    opacity: 0;
    animation-delay: 2s;
    animation-name: intro-title;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    content: '.';
}
