.admin-create-page {
    padding: 20px;
    margin-top: 10px;
    background-color: #202020;
    border-radius: 5px;
}

.admin-create-page label {
    display: block;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
}

.create-select {
    width: 100%;
}

.fieldset {
    margin-bottom: 20px;
}

.admin-create-page input[type="text"],
.admin-create-page input[type="submit"],
.admin-create-page textarea {
    width: 100%;
    height: 42px;
    padding-left: 10px;
    margin: 5px 0 16px 0;
    box-sizing: border-box;
    background-color: black;
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-radius: 16px;
    outline: none;
    color: white;
    text-transform: uppercase;
    font-family: Barlow, sans-serif;
    font-size: 18px;
    font-weight: 900;
    transition: border-color .2s ease-out;
}

.admin-create-page textarea {
    height: 84px;
    padding: 10px;
}

.admin-create-page input[type="text"]:focus {
    border-color: rgba(255, 255, 255, 0.75);
}

.admin-create-page input[type="submit"] {
    margin-top: 20px;
}

.fieldset-checkbox {
    margin: 32px 0 0 0;
}

.fieldset-checkbox input[type="checkbox"] {
    width: 24px;
    height: 24px;
}

.fieldset-checkbox label {
    margin-left: 14px;
}

.admin-create-page input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-radius: 16px;
    padding: 6px 12px;
    box-sizing: border-box;
    margin-top: 60px;
    cursor: pointer;
    text-align: center;
}

.inline-block {
    display: inline-block !important;
}
