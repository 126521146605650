.preview-item {
    position: relative;
    padding: 20px;
    margin-top: 20px;
    box-sizing: border-box;
    border-bottom: 3px solid aqua;
    background-color: #202020;
    border-radius: 16px;
}

.preview-item--content {
    display: flex;
    justify-content: space-between;
}

.preview-item--content-left {
    width: 65%;
}

.preview-item--content-right {
    width: 35%;
    text-align: right;
}

.preview-item--actions {
    margin-top: 20px;
    text-align: right;
}

.preview-item--title {
    margin: 0 0 -16px 0;
    font-size: 24px;
    font-weight: normal;
}

.preview-item--type {
    text-transform: uppercase;
    font-family: Barlow, sans-serif;
    font-size: 16px;
    font-weight: 900;
}

.preview-item--text {
    margin-bottom: 0;
    font-family: Barlow, sans-serif;
    font-size: 18px;
    font-weight: 900;
}

.preview-item--image {
    width: 100px;
    height: 100px;
    margin: 0;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 16px;
    object-fit: cover;
}

.preview-item--buttons {
    display: flex;
    justify-content: space-between;
}

.preview-item--link {
    display: inline-block;
    width: 100px;
}

.preview-item--button {
    height: 40px;
    padding: 10px;
    background-color: rgba(0, 255, 94, 0.5);
    border: 2px solid #32e875;;
    border-radius: 8px;
    outline: none;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-family: Barlow, sans-serif;
    font-size: 14px;
    font-weight: 900;
}

.preview-item--buttons--delete {
    width: 100px;
    background-color: rgba(255, 0, 0, 0.5);
    border-color: #ff1a1a;
    color: rgba(255, 255, 255, 0.8);
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #32e875;
    }
    8% {
        box-shadow: 0 0 0 2px #32e875;
    }
    15% {
        box-shadow: 0 0 0 3px rgba(50, 232, 117, 0.7);
    }
    25% {
        box-shadow: 0 0 0 4px rgba(50, 232, 117, 0.2);
    }
    30% {
        box-shadow: 0 0 0 5px rgba(50, 232, 117, 0);
    }
    50% {
        box-shadow: 0 0 0 5px rgba(50, 232, 117, 0);
    }
    100% {
        box-shadow: 0 0 0 5px rgba(50, 232, 117, 0);
    }
}

.pulse {
    box-shadow: 0 2px 0 0 #32e875;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: pulse;
    animation-timing-function: ease-out;
    border: none;
}
