.content-container {
    padding: 20px;
    font-family: Barlow, sans-serif;
    font-size: 28px;
}

.content-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #131313;
}
