.content-socials--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.content-socials--container-flex {
    text-transform: uppercase;
    font-weight: bold;
}

.content-socials--container-flex > div:not(:first-child) {
    margin: 28px 0;
}

.content-socials--container-flex > div > a {
    color: white;
    text-decoration: none;
}
